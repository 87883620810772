import React, { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import { tablet } from 'styles/breakpoints';
import Like from 'assets/icons/upgrade/like.svg';

interface UpsellReviewSectionProps {
  title: string;
  subtitle: string;
  imgList: string[];
}

const Container = styled.div`
  padding: 4rem 1rem;
  background-color: #f0f0f0;
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${tablet} {
    padding: 3rem 1rem;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
`;

const TitleStyled = styled(Text)`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.29rem;
`;

const Subtitle = styled(Text)`
  color: #000;
  font-size: 1rem;
  line-height: 1.4375rem;
  padding: 0.25rem 1rem 1.5rem;
  text-align: center;
  @media${tablet} {
    padding: 0.25rem 1rem 1rem;
  }
`;

const ImgContainer = styled.div`
  max-width: 73.8rem;
  width: 100%;
  display: flex;
  margin: 0 auto;
  gap: 1.25rem;
  @media ${tablet} {
    max-width: 21.4375rem;
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const UpsellReviewSection: FC<UpsellReviewSectionProps> = ({
  title,
  subtitle,
  imgList,
  ...props
}) => (
  <Container>
    <InnerContainer>
      <TitleContainer>
        <Like />
        <TitleStyled dangerouslySetInnerHTML={{ __html: title }} />
      </TitleContainer>
      <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
      <ImgContainer>
        {imgList?.map((img, i) => (
          <DynamicImage key={i} src={img} alt="Review" />
        ))}
      </ImgContainer>
    </InnerContainer>
  </Container>
);

export default UpsellReviewSection;
