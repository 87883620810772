import React, { FC } from 'react';
import styled from 'styled-components';

import BannerOne from '../BannerOne';
import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Headline from '../Headline';
import Button from '../Button';
import CancelButton from '../CancelButton';
import { ImageWithContainer } from '../..';
import List from '../List';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import { DynamicImage } from 'components';
import UpsellReviewSection from '../UpsellReviewSection';

interface AppSubscriptionProps {}

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f0f0f0;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 31.91rem;
  width: 100%;
`;
const CancelButtonStyled = styled(CancelButton)`
  margin: 0.75rem 0 3rem 0;
`;

const ListStyled = styled(List)`
  padding-top: 2rem !important;
  padding-bottom: 3rem !important;
  @media ${tablet} {
    padding-top: 0.5rem !important;
    padding-bottom: 1rem !important;
  }
`;

const TopTextContainer = styled.div`
  background-color: #f0f0f0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem 2.25rem;
  @media ${tablet} {
    padding: 1rem;
  }
`;

const HeadlineAlt = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 2.8rem */
  max-width: 48rem;
  @media ${tablet} {
    max-width: 30.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const Label = styled.p`
  max-width: 30.5rem;
  color: #28293d;
  font-size: 1.125rem;
  padding: 0.75rem 1rem 2.25rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
    padding: 1rem 1rem 4rem;
  }
`;

const DynamicImageStyled = styled(DynamicImage)`
  max-width: 30.5rem;
  width: 100%;
  height: 100%;
`;

const ImgContainer = styled.div`
  background-color: #f0f0f0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;
const DynamicImageStyledBottom = styled(DynamicImage)`
  max-width: 21.4rem;
  width: 100%;
  height: 100%;
`;

const ImgContainerBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;

const LabelStyled = styled(Label)`
  max-width: 30.5rem;
`;

const HeadlineStyled = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
`;

const AppSubscription: FC<AppSubscriptionProps> = ({
  paymentSteps,
  headlineAlt,
  list,
  planSection,
  PlanSectionComponent,
  headline2,
  headline3,
  label,
  planSection2,
  reviewsSection,
  userHasBoughtPaperbackBook,
}) => {
  const { isTablet } = useQuery();
  return (
    <>
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <TopTextContainer>
        <HeadlineAlt>{headlineAlt}</HeadlineAlt>
      </TopTextContainer>
      <ImgContainer>
        <DynamicImageStyled src="upsell/app.png" alt="Welcome baby app" />
      </ImgContainer>
      <PlanSectionComponent {...planSection} />
      <ListStyled {...list} />
      <Headline {...headline2} />
      <HeadlineStyled>{headline3}</HeadlineStyled>
      <Label dangerouslySetInnerHTML={{ __html: label }} />
      <ImgContainerBottom>
        <DynamicImageStyledBottom src="upsell/app.png" alt="Welcome baby app" />
      </ImgContainerBottom>
      <PlanSectionComponent
        {...planSection2}
        cardPadding="1rem 1rem 4rem"
        cardPaddingMobile="2rem 1rem 3rem"
      />
      <UpsellReviewSection {...reviewsSection} />
    </>
  );
};

export default AppSubscription;
